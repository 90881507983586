<template>
    <nav class="flex gap-6 md:items-center">
        <div class="max-xl:grid-cols-2 flex-grow grid gap-4 xl:grid-flow-col ">
            <!-- Loop through the filters groups -->
            <select class="max-md:col-span-2 max-xl:col-span-1 p-4 text-sm rounded-md form-select bg-gray-8 text-black" v-for="filter in filters" :key="filter.handle" @click="toggleFilter(filter.handle, $event.target.value)">
                <!-- Loop through the filter items -->
                <option v-for="item in filter.items" :key="item.slug" :value="item.slug">{{ item.title || item.label }}</option>
            </select>
        </div>

        <!-- This isn't functional yet -->
        <div class="max-md:flex-col flex gap-2 md:items-center">
            <button :class="{ 'is-active' : activeView === 'list' }" @click="activeView = 'list'">
                <span v-html="ViewList" class="flex items-center justify-center w-9 h-9"></span>
            </button>

            <button :class="{ 'is-active' : activeView === 'cards' }" @click="activeView = 'cards'">
                <span v-html="ViewCards" class="flex items-center justify-center w-9 h-9"></span>
            </button>
        </div>
    </nav>
</template>

<script setup>
    import { ref } from 'vue';

    /* Import SVGs for the view buttons */
    import ViewList from '/src/svgs/ViewList.svg?raw';
    import ViewCards from '/src/svgs/ViewCards.svg?raw';

    /* Define the refs, props and emits */
    const activeView = ref('list');
    const activeFilters = ref([]);
    const emit = defineEmits(['updateActiveFilters']);
    const props = defineProps({
        filters: {
            type: Array,
            default: () => []
        }
    });

    /* Function to toggle the active state of a filter item */
    const toggleFilter = (handle, value) => {
        if (value) {
            /* Search and replace the array item with the same groupSlug if it exists */
            const index = activeFilters.value.findIndex(filter => filter.groupSlug === handle);

            if (index !== -1) { // If the filter is already in the activeFilters array, update the value
                activeFilters.value[index].value = value;
            } else { // If the filter is not in the activeFilters array, add it
                activeFilters.value.push({
                    'value': value,
                    'handle': handle,
                    'filterType': 'fieldOption',
                    'groupSlug': handle,
                });
            }

            /* Emit the active filters to the parent component */
            emit('updateActiveFilters', activeFilters.value);
        }
    };

</script>

<style scoped>
    button {
        color: theme("colors.gray.7");
    }

    button.is-active {
        color: theme("colors.gray.12");
    }
</style>

